// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements

export const environmentProd = {
  production: true,
  appVersion: '0.2',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  apiUrl: 'https://api.planejebem.app/rest',
  apiFamilyUrl: 'https://api.planejebem.app/rest/family',
  apiAssetsUrl: 'https://api.planejebem.app',
  GOOGLE_KEY: '838381896557-16ei1jcfjqauuagrotl57af0ditrpq49.apps.googleusercontent.com',
  GOOGLE_TAG: 'GTM-P7SKX7DW',
  FACEBOOK_KEY: '206795227654091',
  RDS_BASE_URL: "https://api.rd.services/",
  RDS_KEY: "bNVAQLhcObXXLCtoVvIXGDngDETdDOBaVvty",
  RDS_TOKEN: "b8f7327594bf1028d0eb2e0b898f23c3",
  VALUE_PAYMENT: 348,
  COMPANY: 'mawdy',
  COOKIE_CONSENT: 'mawdy'
};

export const environmentTokio = {
  production: false,
  appVersion: "0.2",
  USERDATA_KEY: "authf649fc9a5f533",
  isMockEnabled: true,
  apiUrl: 'https://api.planejebem.app/rest',
  apiFamilyUrl: 'https://api.planejebem.app/rest/family',
  apiAssetsUrl: 'https://api.planejebem.app',
  GOOGLE_KEY:
    "838381896557-2606ckk0dqc6al56s6a9h5a2qr94ig86.apps.googleusercontent.com",
  GOOGLE_TAG: 'G-6WVJGDJQ31',
  FACEBOOK_KEY: "482124826091204",
  RDS_BASE_URL: "https://api.rd.services/",
  RDS_KEY: "bNVAQLhcObXXLCtoVvIXGDngDETdDOBaVvty",
  RDS_TOKEN: "b8f7327594bf1028d0eb2e0b898f23c3",
  VALUE_PAYMENT: 348,
  COMPANY: 'mawdy'
};

export const environmentDev = {
  production: false,
  appVersion: "0.2",
  USERDATA_KEY: "authf649fc9a5f533",
  isMockEnabled: true,
  apiUrl: "https://api.hml.planejebem.app/api/x/rest",
  apiFamilyUrl: "https://api.hml.planejebem.app/api/x/rest/family",
  apiAssetsUrl: "https://api.hml.planejebem.app/api/assets",
  GOOGLE_KEY:
    "838381896557-2606ckk0dqc6al56s6a9h5a2qr94ig86.apps.googleusercontent.com",
  GOOGLE_TAG: 'G-6WVJGDJQ31',
  FACEBOOK_KEY: "482124826091204",
  RDS_BASE_URL: "https://api.rd.services/",
  RDS_KEY: "bNVAQLhcObXXLCtoVvIXGDngDETdDOBaVvty",
  RDS_TOKEN: "b8f7327594bf1028d0eb2e0b898f23c3",
  VALUE_PAYMENT: 348,
  COMPANY: ''
};
export const environmentLocal = {
  production: false,
  appVersion: '0.2',
  USERDATA_KEY: 'authf649fc9a5f533',
  isMockEnabled: true,
  apiUrl: 'http://localhost:8991/rest',
  apiFamilyUrl: 'http://localhost:8991/rest/family',
  apiAssetsUrl: 'http://localhost:9032',
  GOOGLE_KEY: '838381896557-2606ckk0dqc6al56s6a9h5a2qr94ig86.apps.googleusercontent.com',
  GOOGLE_TAG: 'G-6WVJGDJQ31',
  FACEBOOK_KEY: '482124826091204',
  RDS_BASE_URL: "https://api.rd.services/",
  RDS_KEY: "bNVAQLhcObXXLCtoVvIXGDngDETdDOBaVvty",
  RDS_TOKEN: "b8f7327594bf1028d0eb2e0b898f23c3",
  VALUE_PAYMENT: 348,
  Public_path: "../../../../../../public",
  COMPANY: ''
};

export const environmentStaging = {
  production: true,
  appVersion: '0.2',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  apiUrl: 'https://staging.api.planejebem.app/rest',
  apiFamilyUrl: 'https://staging.api.planejebem.app/rest/family',
  apiAssetsUrl: 'https://staging.api.planejebem.app',
  GOOGLE_KEY: '838381896557-16ei1jcfjqauuagrotl57af0ditrpq49.apps.googleusercontent.com',
  GOOGLE_TAG: 'GTM-WX54TS5F',
  FACEBOOK_KEY: '206795227654091',
  RDS_BASE_URL: "https://api.rd.services/",
  RDS_KEY: "bNVAQLhcObXXLCtoVvIXGDngDETdDOBaVvty",
  RDS_TOKEN: "b8f7327594bf1028d0eb2e0b898f23c3",
  VALUE_PAYMENT: 348,
  COMPANY: ''
};

const discriminatorEnv = (env: string) => {
  switch (env) {
    case 'DEV':
      return environmentDev;
    case 'LOCAL':
      return environmentLocal;
    case 'PROD':
      return environmentProd;
    case 'TOKIO':
      return environmentTokio;
    case 'STAGING':
      return environmentStaging;
    default:
      return environmentDev;
  }
};

export const environment = discriminatorEnv('PROD');

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
